import React, { useEffect } from 'react';
import styles from '../assets/styles/AdsPopup.module.css';
import classes from '../assets/styles/Promotion.module.css';
import { motion } from 'framer-motion';
import { langChange } from '../lang';
import { useDispatch, useSelector } from 'react-redux';
import { promotionShowHandler } from '../store/slices/promotionSlice';

const PromotionModal = ({ promotion, event, appliedPromotions, loading }) => {
    const { promotionShow } = useSelector(state => state.promotionShow);
    const dispatch = useDispatch();
    const closeHandler = (e) => {
        if (e.target == e.currentTarget) {
            dispatch(promotionShowHandler(0))
        }
    }

    const isApplied = appliedPromotions.filter(promo => promo.promotion_name === promotion.en_title);
    
    useEffect(() => { }, [promotionShow]);
    return (
        promotion && <div onClick={closeHandler}
            className={styles.modalBackdrop} style={{ display: promotionShow !== 0 ? 'flex' : 'none' }}>
            <motion.div
                initial={{ top: '-100px' }}
                animate={{ top: 0 }}
                transition={{ duration: 0.5 }}
                className={styles['ads-box']}>
                <div className={classes.promotion_popup}>
                    <div className={classes.promotion_period}>
                        <strong className={classes.promotion_period_item}>{langChange.from} : {promotion.from}</strong>
                        <strong className={classes.promotion_period_item}>{langChange.to} : {promotion.to}</strong>
                    </div>
                    <img style={{ width: '100%', background: '#000' }} src={promotion.photo} alt="Promotion Image" />
                    <h2 className={classes.promotion_en_title}>{promotion.en_title}</h2>
                    <h2 className={classes.promotion_mm_title}>{promotion.mm_title}</h2>
                    <p className={classes.promotion_des}>{promotion.description}</p>
                    <div className={classes.promotion_action}>
                        <button className={classes.promotion_btn} style={{ background: isApplied.length > 0 && '#333258', border: isApplied.length > 0 && '1px solid #333258', cursor: isApplied.length > 0 && 'auto' }} onClick={event} disabled={loading === promotion.id || isApplied.length > 0}>
                        {loading === promotion.id ? (
                            <div className={classes.loading_container}>
                                {langChange.loading}
                                <div className={classes.dot_flashing}></div>
                            </div>
                        ) : (
                            <span>
                                {isApplied?.length > 0 ? `${langChange.applied}` : `${langChange.apply}`}
                            </span>
                        )}
                        </button>
                        <button className={classes.promotion_btn} onClick={() => { dispatch(promotionShowHandler(0)); promotion = [] }}>{langChange.later}</button>
                    </div>
                </div>
            </motion.div>
        </div>
    )
}

export default PromotionModal
