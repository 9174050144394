import { createSlice } from "@reduxjs/toolkit";

const promotionSlice = createSlice({
  name: "promotionShow",
  initialState: {
    promotionShow: 0,
  },
  reducers: {
    promotionShowHandler: (state, action) => {
      state.promotionShow = action.payload;
    },
  },
});

export const { promotionShowHandler } = promotionSlice.actions;
export default promotionSlice.reducer;