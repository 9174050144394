import React, { useEffect, useState } from 'react';
import main_class from '../assets/styles/Main.module.css';
import classes from '../assets/styles/Promotion.module.css';
import Header from '../layout/Header';
import FullSidebar from '../layout/FullSidebar';
import Sidebar from '../layout/Sidebar';
import PromotionItem from './PromotionItem';
import { useNavigate } from 'react-router-dom';
import { PROMOTION_API, PROMOTION_CHECK_API, PROMOTION_LIKE_API } from '../services/constant';
import { getMethod, postMethod } from '../services';
import Footer from '../layout/Footer';
import Loader from '../utils/Loader';
import { useSelector } from 'react-redux';
import PromotionModal from './PromotionModal';

export default function Promotion() {
    const [promotions, setPromotions] = useState([]);
    const [loading, setLoading] = useState(0);
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();
    const [appliedPromotions, setAppliedPromotions] = useState([]);
    const {promotionShow} = useSelector(state => state.promotionShow);
    const [promotionDetail, setPromotionDetail] = useState([]);

    useEffect(()=>{
        promotions.length > 0 && setPromotionDetail(promotions?.find(promo => promotionShow === promo.id))
    }, [promotionShow])

    const fetchPromotion = async () => {
        setLoader(true);
        const res = await getMethod(
            `${PROMOTION_API}?app_name_id=${process.env.REACT_APP_ID}`
        );
        if (res.con) {
            setLoader(false);
            setPromotions(res.data);
        }
        const token = localStorage.getItem("r_c_auth");
        if (token) {
            setLoader(true)
            const deToken = JSON.parse(token)
            const check = await getMethod(
                `${PROMOTION_CHECK_API}?user_id=${deToken.user_id}`, deToken.token
            );
            if (check.con) {
                setLoader(false)
                setAppliedPromotions(check.data);
            } else {
                setLoader(false)
            }
        }
    };

    useEffect(() => {
        fetchPromotion();
    }, []);

    const applyHandler = async (id, title) => {
        setLoader(true);
        setLoading(id);
        const token = localStorage.getItem("r_c_auth");
        if (token) {
          const deToken = JSON.parse(token);
          const data = {
            user_id: deToken.user_id,
            promotion_id: id,
          };
          const res = await postMethod(PROMOTION_LIKE_API, data, deToken.token);
          if (res.con) {
            setLoader(false);
            setLoading(0);
            setAppliedPromotions([...appliedPromotions, {promotion_id: id, promotion_name: title}])
          } else {
            setLoader(false);
            setLoading(0);
          }  
        } else {
          setLoader(false)
          setLoading(0);
          navigate('/login');
        }
      }
    return (
        <>
            <div className={main_class['container-main']}>
                <Header />
                <Sidebar />
                <FullSidebar />
                <div className={classes.promotion_container}>
                    {
                        promotions.length > 0 && promotions.map(promotion => <PromotionItem key={promotion.id} promotion={promotion} event={() => {applyHandler(promotion.id, promotion.en_title)}} appliedPromotions={appliedPromotions} loading={loading}/>)
                    }
                    {
                        promotionDetail && <PromotionModal promotion={promotionDetail} event={() => {applyHandler(promotionDetail.id, promotionDetail.en_title)}} appliedPromotions={appliedPromotions} loading={loading}/>
                    }
                </div>
                <Footer />
            </div>
            {
                loader && <Loader />
            }
        </>
    )
}
