import React, { useEffect, useState } from 'react'
import classes from '../assets/styles/Button.module.css';
import { langChange } from '../lang';
import { useSelector } from 'react-redux';
const Button = ({type,label,bgColor,bgHover,event,disable = false,loading = false,setting}) => {
  const [hover,setHover] = useState(false);
 
  const { lang } = useSelector(state => state.lang)
  useEffect(()=>{}, [lang])

  return (
    <button type={type} className={classes.btn} 
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    style={{
      border: `2px solid ${bgColor}`,
      backgroundColor: hover ? bgHover: bgColor,
      width: setting?.width,
      marginBottom: setting?.marginBottom,
    }}
    disabled={disable}
    onClick={event}
    >
        {
          loading ?
          <div className={classes['loading-container']}>
              {langChange.loading}
              <div className={classes['dot-flashing']}></div>
          </div>:
          label
        }
    </button>
  )
}

export default Button;
