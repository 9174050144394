import React, { useEffect, useState } from 'react';
import classes from '../assets/styles/PasswordCreate.module.css';
import Button from '../utils/Button';
import { useNavigate } from 'react-router-dom';
import Input from '../utils/Input';
import { useSelector } from 'react-redux';
import logo from '../assets/logo/logo.png'
import { postMethod } from '../services';
import { PASSWORD_RESET_API } from '../services/constant';
import SweetAlert from 'sweetalert2';
import config from '../config/config.json';
import { langChange } from '../lang';

const PasswordCreate = () => {
  const [loading,setLoading] = useState(false);
  const [newPassword,setNewPassword] = useState("");
  const [confirmPassword,setConfirmPassword] = useState("");

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => { }, [lang]);

  const [errorNewPassword,setErrorNewPassword] = useState("");
  const [errorConfirmPassword,setErrorConfirmPassword] = useState("");

  const {registerVal} = useSelector(state => state.registerSlice);
  const navigate = useNavigate();
  useEffect(() => {
    if(!registerVal.phone){
       navigate('/forgot-password');
    };
 },[registerVal]);

 const newPasswordHandler = e => {
    if(e.target.value.length > 8){
      return;
    };
    const numericValue = e.target.value.replace(/\D/g, "");
    e.target.value = numericValue;
    setNewPassword(e.target.value);
    setErrorNewPassword("");
 };

 const confirmPasswordHandler = e => {
  if(e.target.value.length > 8){
    return;
  };
  const numericValue = e.target.value.replace(/\D/g, "");
  e.target.value = numericValue;
  setConfirmPassword(e.target.value);
  setErrorConfirmPassword("");
 };

 useEffect(()=>{
  const token = localStorage.getItem('r_c_auth')
  token && navigate('/')
},[])
 
 const submitHandler = async e => {
     e.preventDefault();
     setLoading(true);
     const data = {
         ...registerVal,
         password: newPassword,
         password_confirmation: confirmPassword,
         app_name_id: config.app_name_id
     }
     if(newPassword === confirmPassword){
        const res = await postMethod(PASSWORD_RESET_API, data);
         if(res){
          setLoading(false);
          if(res === 'Failed to fetch'){
            SweetAlert.fire({
              width: '330px',
              icon: 'error',
              title: 'Connection!',
              text: langChange.please_check_your_internet_connection,
              confirmButtonText: 'Ok',
            });
          };
          if(res?.con){
              SweetAlert.fire({
                width: '330px',
                icon: 'success',
                title: 'Success!',
                text: langChange.change_password_successful,
                confirmButtonText: 'Ok',
              }).then(result => {
                  if(result.isConfirmed){
                      navigate('/login');
                  };
              });
          }else{
              if(res?.data?.error){
                setErrorNewPassword(res.data.error);
                navigate(-1);
              }
          };
         }
     }else{
        setErrorNewPassword(langChange.password_and_confirm_password_must_match);
        setErrorConfirmPassword(langChange.password_and_confirm_password_must_match);
        setLoading(false);
     }
 };


 
  return (
    <div className={classes['container-main']}>
      <div className={classes['logo-position']}>
           <img src={logo} alt="logo" className={classes.logo} onClick={()=> navigate('/')}/>
      </div>
      <div className={classes['auth-card']}>
            <div className={classes['auth-card-header']}>
              {langChange.createPass}
            </div>
            <div className={classes['auth-card-body']}>
                <form onSubmit={submitHandler}>
                  <Input type="password" placeholder={langChange.newpass} value={newPassword} event={newPasswordHandler} error={errorNewPassword}/>
                  <Input type="password" placeholder={langChange.conpass} value={confirmPassword} event={confirmPasswordHandler} error={errorConfirmPassword} />
                  <div className={classes['forgotpass-submit']}>
                      <Button label={langChange.confirm} type="submit" bgColor="#6b67eb" bgHover="#333258" disable={loading} loading={loading} />
                  </div>
                </form>
            </div>
      </div>
    </div>
  )
}

export default PasswordCreate
