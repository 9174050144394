import React from 'react'
import { BrowserRouter as Router, Routes,Route} from "react-router-dom";
import Main from '../components/Main';
import Auth from '../auths';
import Verify from '../auths/Verify';
import ForgotPassword from '../auths/ForgotPassword';
import PasswordCreate from '../auths/PasswordCreate';
import ChangePassword from '../auths/ChangePassword';
import GameList from '../components/GameList';
import Deposit from '../components/Deposit';
import Withdraw from '../components/Withdraw';
import RouteGuard from './RouteGuard';
import ForgotVerify from '../auths/ForgotVerify';
import { useDispatch } from 'react-redux';
import { langTypeHandler } from '../store/slices/langSlice';
import { langChange } from '../lang';
import Promotion from '../components/Promotion';
import BettingHistory from '../components/BettingHistory';
const Routers = () => {
  const dispatch = useDispatch();
  const _r_c_lang = localStorage.getItem("_r_c_lang");
  if (_r_c_lang) {
    dispatch(langTypeHandler(_r_c_lang));
    langChange.setLanguage(_r_c_lang);
  } else {
    localStorage.setItem("_r_c_lang", "en");
    langChange.setLanguage("en");
    dispatch(langTypeHandler("en"));
  }
  return (
    <Router>
        <Routes>
           {/* ----------- auth ---------- */}
            <Route path='/login' element={<Auth/>} />
            <Route path='/signup' element={<Auth/>} />
            <Route path='/verify' element={<Verify />} />
            <Route path='/forgot-password' element={<ForgotPassword/>} />
            <Route path='/verify/password' element={<ForgotVerify/>} />
            <Route path='/verify/password-create' element={<PasswordCreate/>} />
            <Route path='/change-password' element={<RouteGuard><ChangePassword/></RouteGuard>} />

            {/* ----------- games ---------- */}
            <Route path="/:name/:id" element={<RouteGuard><GameList /></RouteGuard>} />

            {/* ----------- Payment ---------- */}
            {/* <Route path="/deposit" element={<RouteGuard><Deposit /></RouteGuard>} />
            <Route path="/deposit-history" element={<RouteGuard><Deposit /></RouteGuard>} />
            <Route path="/withdraw" element={<RouteGuard><Withdraw /></RouteGuard>} />
            <Route path="/withdraw-history" element={<RouteGuard><Withdraw /></RouteGuard>} /> */}

            {/* ----------- default ---------- */}
            <Route path='/' element={<Main/>} />
            <Route path='/promotions' element={<Promotion/>} />
            <Route path='/betting-history' element={<BettingHistory/>} />
        </Routes>
    </Router>
  )
}

export default Routers
