const domain = process.env.REACT_APP_DOMAIN;
const node_domain = process.env.REACT_APP_NODE_DOMAIN;
// const domain = 'http://192.168.1.9:8080/';
// console.log(domain);

export const REGISTER_API = `${domain}api/okie/register`;
export const REGISTER_OTP_API = `${domain}api/getOTP`;
export const NAME_CHECK_API = `${domain}api/check-name`;
export const PASSWORD_CREATE_OTP_API = `${domain}api/password/create`;
export const PASSWORD_RESET_API = `${domain}api/password/reset`;
export const LOGIN_API = `${domain}api/okie/login`;
export const CHANGE_PASSWORD_API = `${domain}api/change-password`;
export const USER_INFO_API = `${domain}api/user_info`;
export const PROVIDER_CONNECT_API = `${domain}api/provider-connect-lists`;
export const PROVIDER_API = `${domain}api/providers`;
export const GAMES_LIST_API = `${domain}api/game-lists`;
export const GAME_LAUNCH_API = `${domain}api/slot/init`;
export const PAYMENT_PROVIDER_API = `${domain}api/payment-providers`;
export const USER_DEPOSIT_API = `${domain}api/okie/deposits`;
export const DEPOSIT_HISTORY_API = `${domain}api/slot/deposits`;
export const USER_WITHDRAW_API = `${domain}api/okie/withdraws`;
export const WITHDRAW_HISTORY_API = `${domain}api/slot/withdraws`;
export const BANNER_API = `${domain}api/banners`;
export const CAPTION_API = `${domain}api/caption`;
export const POPUP_API = `${domain}api/popup`;
export const PROMOTION_API = `${domain}api/promotions`;
export const PROMOTION_LIKE_API = `${domain}api/promotion-like`;
export const PROMOTION_USER_API = `${domain}api/promotion-users`;
export const PROMOTION_CHECK_API = `${domain}api/promotion-check`;
export const BETTING_HISTORY_API = `${node_domain}api/betting-history`;
