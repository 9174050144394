import React, { useEffect, useState } from 'react'
import Input from '../utils/Input';
import Button from '../utils/Button';
import { Link, useNavigate } from 'react-router-dom';
import classes from '../assets/styles/Signin.module.css';
import { postMethod } from '../services';
import { LOGIN_API } from '../services/constant';
import SweetAlert from 'sweetalert2';
import config from '../config/config.json';
import { langChange } from '../lang';
import { useDispatch, useSelector } from 'react-redux';
import { loginStatusHandler } from '../store/slices/loginSlice';

const Signin = () => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [errorName, setErrorName] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deviceName,setDeviceName] = useState("Unknown Device");
  const [browserName,setBrowserName] = useState("Unknown Browser");

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => { }, [lang]);

  const  getDeviceAndBrowserInfo = () => {
    const userAgent = navigator.userAgent;
    // Determine the operating system
    if (userAgent.match(/Windows/i)) {
      setDeviceName("Windows");
    } else if (userAgent.match(/Mac OS/i)) {
      setDeviceName("Mac OS");
    } else if (userAgent.match(/Ubuntu/i)) {
      setDeviceName("Linux");
    } else if (userAgent.match(/Android/i)) {
      setDeviceName("Android");
    } else if (userAgent.match(/iOS/i)) {
      setDeviceName("iOS");
    }
  
    // Determine the browser
    if (userAgent.match(/Chrome/i)) {
      setBrowserName("Google Chrome");
    } else if (userAgent.match(/Firefox/i)) {
      setBrowserName("Firefox");
    } else if (userAgent.match(/Safari/i)) {
      setBrowserName("Apple Safari");
    } else if (userAgent.match(/Edge/i)) {
      setBrowserName("Microsoft Edge");
    } else if (userAgent.match(/Opera/i)) {
      setBrowserName("Opera");
    }
  };

  useEffect(()=> {getDeviceAndBrowserInfo()}, [])

  const nameHandler = e => {
    setName(e.target.value)
    // const inputValue = e.target.value;
    // // Ensure the first three characters are A-Z
    // const lettersValue = inputValue.replace(/[^A-Za-z]/g, "");
    // const firstThreeLetters = lettersValue.substring(0, 3);
    // let lastFiveNumbers;
    // // Ensure the last five characters are 0-9
    // if (firstThreeLetters.length >= 3) {
    //   const numbersValue = inputValue.replace(/[^0-9]/g, "");
    //   lastFiveNumbers = numbersValue.substring(0, 5);
    // } else {
    //   lastFiveNumbers = ""
    // }
    // // Combine the first three letters and last five numbers
    // const finalValue = `${firstThreeLetters}${lastFiveNumbers}`;
    // if (finalValue.length > 8) {
    //   return
    // } else {
    //   // setErrorName("- The first three letters is A-Z and last five letters is 0-9 \n- ( Eg: abc12345 )");
    //   setErrorName(langChange.userNameMessage);
    // }
    // setName(finalValue);
    // e.target.value.length === 8 && setErrorName("")
  };
  const passwordHandler = e => {
    if (e.target.value.length > 8) {
      return;
    };
    const numericValue = e.target.value.replace(/\D/g, "");
    e.target.value = numericValue;
    setPassword(e.target.value);
    setErrorPassword("");
  }
  const loginHandler = async (e) => {
    e.preventDefault();
    if (name) {
      if (password) {
        setLoading(true);
        const fetchIp = await fetch('https://api.ipify.org?format=json')
          .then(response => response.json()).then(data => data)
        const data = {
          name, password,
          ip_address: fetchIp.ip,
          browser_name: browserName,
          device_name: deviceName,
          app_name_id: config.app_name_id,
        };
        if (fetchIp.ip) {
          const res = await postMethod(LOGIN_API, data);
        if (res) {
          setLoading(false);
          if (res === 'Failed to fetch') {
            SweetAlert.fire({
              width: '330px',
              icon: 'error',
              title: 'Connection!',
              text: langChange.please_check_your_internet_connection,
              confirmButtonText: 'Ok',
            });
          }
          if (res.con) {
            SweetAlert.fire({
              width: '330px',
              icon: 'success',
              title: 'Success!',
              text: langChange.loginSuccess,
              confirmButtonText: 'Welcome',
            }).then(result => {
              if (result.isConfirmed) {
                dispatch(loginStatusHandler(true));
                localStorage.setItem('r_c_auth', JSON.stringify(res.data));
                navigate('/');
              };
            });
          } else {
            if (res.data.name) {
              setErrorName(res.data.name[0]);
            };
            if (res.data.password) {
              setErrorPassword(res.data.password[0]);
            };
            if (res.data.error) {
              setErrorName(res.data.error);
            };
          };
        }
        } else {
          setLoading(false)
          SweetAlert.fire({
            width: "330px",
            icon: "error",
            title: "Sorry!",
            text: `Please check your internet connection & try again!`,
            confirmButtonText: "Ok",
          });
        }
      }  else {
        setErrorPassword(langChange.password_is_required);
      }
    } else {
      setErrorName(langChange.name_is_required);
    }
  }
  return (
    <>
      <div className={classes['auth-card-body']}>
        <p className={classes['welcome-text']}>{langChange.welcomeBack}</p>
        <form onSubmit={loginHandler}>
          <Input type="text" placeholder={langChange.loginName} value={name} event={nameHandler} error={errorName} />
          <Input type="password" placeholder={langChange.password} value={password} event={passwordHandler} error={errorPassword} />
          <div className={classes['submit-position']}>
            <Button label={langChange.login} type="submit" bgColor="#6b67eb" bgHover="#333258" loading={loading} disable={loading} />
            <div className={classes['forgot-text']}>
              <Link to="/forgot-password">
                <span>{langChange.forgetPass}</span>
              </Link>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default Signin
