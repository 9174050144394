import { createSlice } from "@reduxjs/toolkit";

const logoutSlice = createSlice({
    name: 'logout',
    initialState: {
        logoutVal: true,
    },
    reducers: {
        logoutStatusHandler: (state,action) => {
            state.logoutVal = action.payload;
        }
    }
});

export const {logoutStatusHandler} = logoutSlice.actions;
export default logoutSlice.reducer;