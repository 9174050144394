import React from 'react'
import Header from '../layout/Header'
import classes from '../assets/styles/Main.module.css';
import BannerSlide from './BannerSlide';
import ProviderLists from './ProviderLists';
import Footer from '../layout/Footer';
import Sidebar from '../layout/Sidebar';
import FullSidebar from '../layout/FullSidebar';
import BannerCaption from './BannerCaption';
import { useSelector } from 'react-redux';
import AdsPopup from './AdsPopup';
const Main = () => {
  const { login } = useSelector(state => state.login);
  return (
    <>
      {
        login && <AdsPopup />
      }
      <div className={classes['container-main']}>
       <Header />
       <Sidebar />
       <FullSidebar />
       <BannerSlide />
       <BannerCaption />
       <ProviderLists />
       <Footer />
    </div>
    </>
  )
}

export default Main
