import React from "react";
import Routers from "./routes";
import { Provider } from "react-redux";
import { store } from "./store";

document.addEventListener("wheel", function (event) {
  if (document.activeElement.type === "number") {
    document.activeElement.blur();
  }
});

function App() {
  return (
    <Provider store={store}>
      <Routers />
    </Provider>
  );
}

export default App;
