import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BiSolidCopy } from 'react-icons/bi';
import Input from '../utils/Input';
import Button from '../utils/Button';
import classes from '../assets/styles/DepositForm.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { depositHandler } from '../store/slices/depositSlice';
import { getMethod, postMethodFormData } from '../services';
import { PAYMENT_PROVIDER_API, USER_DEPOSIT_API, USER_INFO_API } from '../services/constant';
import SweetAlert from 'sweetalert2';
import Loader from '../utils/Loader';
import PaymentList from '../utils/PaymentList';
import { copyToClipboard } from '../utils/Helper';
import { useNavigate } from 'react-router-dom';
import config from '../config/config.json';
import { langChange } from '../lang';

const DepositForm = () => {
    const [loading, setLoading] = useState(true);
    const [payments, setPayments] = useState([]);
    const [userId, setUserId] = useState(null);
    const [userBalance, setUserBalance] = useState(null);
    const [amount, setAmount] = useState("");
    const [confirmState, setConfirmState] = useState(false);
    const [slipImg, setSlipImg] = useState("");
    const [transno, setTransno] = useState("");

    const { lang } = useSelector((state) => state.lang);
    useEffect(() => { }, [lang]);

    const [errorAmount, setErrorAmount] = useState("");
    const [errorSlipImg, setErrorSlipImg] = useState("");
    const [errorTransno, setErrorTransno] = useState("");

    const { value } = useSelector(state => state.depositStoreSlice);
    const dispatch = useDispatch();
    const [choosePayment, setChoosePayment] = useState(null);
    const navigate = useNavigate();

    const fetchUserInfo = async () => {
        const token = localStorage.getItem('r_c_auth');
        if (token) {
            const deToken = JSON.parse(token);
            let res = await getMethod(USER_INFO_API, deToken.token);
            if (res) {
                if (res.con) {
                    setUserId(res?.data?.user_info?.id);
                    setUserBalance(res?.data?.user_info?.balance);
                };
            };
        };
    };

    const fetchPaymentProviders = async () => {
        const token = localStorage.getItem('r_c_auth');
        if (token) {
            const deToken = JSON.parse(token);
            const res = await getMethod(`${PAYMENT_PROVIDER_API}?status=active&app_name_id=${config.app_name_id}`, deToken.token);
            if (res) {
                setLoading(false);
                if (res === 'Failed to fetch') {
                    setLoading(false);
                    SweetAlert.fire({
                        width: '330px',
                        icon: 'error',
                        title: 'Connection!',
                        text: langChange.please_check_your_internet_connection,
                        confirmButtonText: 'Ok',
                    });
                };
                if (res.con) {
                    setPayments(res.data);
                };
            };
        };
    };

    useEffect(() => {
        fetchPaymentProviders();
        fetchUserInfo();
    }, []);

    const customAmountHandler = e => {
        const inputValue = e.target.value;

        if (inputValue >= 1000 && inputValue <= 1000000) {
            setErrorAmount("");
        } else {
            setErrorAmount(langChange.depositLimit);
        }

        if (inputValue.length === 0) {
            setErrorAmount('')
        }

        setAmount(inputValue);

        // let isChecked = document.querySelectorAll('.isChecked');
        // isChecked.forEach(input => {
        //     return input.checked = false;
        // });
    };

    useEffect(() => {
        const data = {
            user_id: userId,
            payment_provider_id: choosePayment?.id,
            amount,
            initial_balance: userBalance
        };
        dispatch(depositHandler(data))
    }, [amount]);

    const nextHandler = () => {
        setErrorAmount("");
        if (value.amount) {
            if (value.amount >= 1000 && value.amount <= 1000000) {
                setConfirmState(true);
            } else {
                setErrorAmount(langChange.minDeposit);
            }
        } else {
            setErrorAmount(langChange.amount_field_is_required);
        };
        
    };

    const depositSubmitHandler = async (e) => {
        e.preventDefault();
        if (slipImg) {
            if (transno) {
                if (transno.length === 6) {
                    setLoading(true);
                    const token = localStorage.getItem('r_c_auth');
                    if (token) {
                        const deToken = JSON.parse(token);
                        const formData = new FormData();
                        formData.append('user_id', userId);
                        formData.append('payment_provider_id', value?.payment_provider_id);
                        formData.append('amount', value?.amount);
                        formData.append('transaction_no', transno);
                        formData.append('slip_image', slipImg);
                        formData.append('initial_balance', userBalance);
                        formData.append('app_name_id', config.app_name_id);
                        const res = await postMethodFormData(USER_DEPOSIT_API, formData, deToken.token)
                        if (res) {
                            setLoading(false);
                            if (res === 'Failed to fetch') {
                                SweetAlert.fire({
                                    width: '330px',
                                    icon: 'error',
                                    title: 'Connection!',
                                    text: langChange.please_check_your_internet_connection,
                                    confirmButtonText: 'Ok',
                                });
                            };
                            if (res.con) {
                                SweetAlert.fire({
                                    width: '330px',
                                    icon: 'success',
                                    title: 'Success!',
                                    text: langChange.deposit_success,
                                    confirmButtonText: 'Ok',
                                }).then(result => {
                                    if (result.isConfirmed) {
                                        navigate('/');
                                    }
                                });
                            }
                        }
                    };
                } else {
                    setErrorTransno(langChange.transaction_no_must_be_6_digits)
                };
            } else {
                setErrorTransno(langChange.transaction_no_is_required)
            }
        } else {
            setErrorSlipImg(langChange.slip_image_is_required)
        };
    };

    const paymentSelectHandler = (payment) => {
        setChoosePayment(payment);
    };

    return (
        <>
            <div className={classes['auth-card-body']}>
                {
                    confirmState ?
                        <>
                            <div className={classes['payment-select']}>
                                <img src={choosePayment?.photo} />
                                <div className='payment-owner-phone'>
                                    <p>{choosePayment?.phone}</p>
                                    <p>{choosePayment?.owner}</p>
                                </div>
                                <span>
                                    <BiSolidCopy onClick={e => copyToClipboard(choosePayment?.phone)} />
                                </span>
                            </div>
                            <p className={classes['note']}>{langChange.remark} : <span>{langChange.pleaseTransfer}</span></p>
                            <hr style={{ marginBottom: '30px', borderColor: '#2e3058' }} />
                            <div className={classes['confirm-container']}>
                                <p>
                                    {langChange.amount}  &nbsp;: <span>{Number(value?.amount)?.toLocaleString('en-US')}</span>
                                </p>
                                <form onSubmit={depositSubmitHandler}>
                                    <Input type="file" event={e => { setErrorSlipImg(""); setSlipImg(e.target.files[0]) }} error={errorSlipImg} />
                                    <Input type="number" value={transno} placeholder={langChange.transactionNoLimit} event={e => {
                                        if (e.target.value.length > 6) {
                                            return;
                                        }
                                        setErrorTransno("");
                                        setTransno(e.target.value)
                                    }
                                    } error={errorTransno} />
                                    <div className={classes['btn-position']}>
                                        <Button label={langChange.confirm} type="submit" bgColor="#6b67eb" bgHover="#333258" loading={loading} disable={loading} />
                                    </div>
                                </form>
                            </div>
                        </>
                        :
                        <>
                            <ul className={classes['payment-flex']}>
                                {
                                    payments.length > 0 &&
                                    payments.map((payment, i) =>
                                        <PaymentList key={i} data={choosePayment} id={payment.id} photo={payment.photo} name={payment.owner} phone={payment.phone} event={e => paymentSelectHandler(payment)} />
                                    )
                                }
                            </ul>
                            {choosePayment &&
                                <form>
                                    <hr style={{ marginBottom: '30px', borderColor: '#2e3058' }} />
                                    <Input type="number" placeholder={langChange.amount} value={amount} event={customAmountHandler} error={errorAmount} />
                                    {/* <div className={classes['amount-container']}>
                                <RadioInput id="1" value="5000" amountEvent={amountHandler} checked={true}/>
                                <RadioInput id="2" value="10000" amountEvent={amountHandler} checked={true}/>
                                <RadioInput id="3" value="20000" amountEvent={amountHandler} checked={true}/>
                                <RadioInput id="4" value="30000" amountEvent={amountHandler} checked={true}/>
                                <RadioInput id="5" value="50000" amountEvent={amountHandler} checked={true}/>
                                <RadioInput id="6" value="100000" amountEvent={amountHandler} checked={true}/>
                                <RadioInput id="7" value="200000" amountEvent={amountHandler} checked={true}/>
                                <RadioInput id="8" value="300000" amountEvent={amountHandler} checked={true}/>
                            </div> */}
                                    <div className={classes['submit-position']}>
                                        <Button label={langChange.next} type="button" bgColor="#6b67eb" bgHover="#333258" event={nextHandler} />
                                    </div>
                                </form>}
                        </>
                }

            </div>
            {
                ReactDOM.createPortal(loading && <Loader />, document.getElementById("portal"))
            }
        </>
    )
}

export default DepositForm
