import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import classes from '../assets/styles/Verify.module.css';
import Button from '../utils/Button';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../assets/logo/logo.png'
import { userDataHandler } from '../store/slices/registerSlice';
import { langChange } from '../lang';

const ForgotVerify = () => {
  const [otp, setOtp] = useState('');
  const {registerVal} = useSelector(state => state.registerSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => { }, [lang]);

  useEffect(()=>{
    const token = localStorage.getItem('r_c_auth')
    token && navigate('/')
  },[])

  useEffect(() => {
     if(!registerVal.phone){
        navigate('/forgot-password');
     };
  },[registerVal]);
  const submitHandler = async (e) => {
      e.preventDefault();
      const data = {
         ...registerVal,
         token: otp
      };
      dispatch(userDataHandler(data));
      navigate('/verify/password-create');
  };
  return (
    <div className={classes['container-main']}>
      <div className={classes['logo-position']}>
           <img src={logo} alt="logo" className={classes.logo} onClick={()=> navigate('/')}/>
      </div>
      <div className={classes['auth-card']}>
            <div className={classes['auth-card-header']}>
              {langChange.verify}
            </div>
            <div className={classes['auth-card-body']}>
                <form onSubmit={submitHandler}>
                    <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        inputType='number'
                        renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
                        renderInput={(props) => <input {...props} className={classes['opt-input']} />}
                    />
                    <div className={classes['verify-submit']}>
                        <Button label={langChange.confirm} type="submit" disable={otp.length === 6 ? false: true} bgColor="#6b67eb" bgHover="#333258"/>
                    </div>
                </form>
            </div>
      </div>
    </div>
  )
}

export default ForgotVerify
