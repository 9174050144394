import React from 'react'
import classes from '../assets/styles/ToggleInput.module.css';
import { FiSearch } from 'react-icons/fi';

const ToggleInput = ({event}) => {
  return (
    <div className={classes['input-group']}>
      <div className={classes['input-enclose']}>
        <input type="text" placeholder="Enter Keyword" id="fldSearch" onChange={event}/>
      </div>
      <div className={classes['toggle-icon']}>
        <label htmlFor="fldSearch" className={classes['icon']}>
            <FiSearch/>
        </label>
      </div>
  </div>
  )
}

export default ToggleInput
