import React, { useEffect, useState } from 'react';
import styles from '../assets/styles/AdsPopup.module.css';
import { motion } from 'framer-motion';
import { POPUP_API } from '../services/constant';
import { getMethod } from '../services';
import { FiX } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { loginStatusHandler } from '../store/slices/loginSlice';

const AdsPopup = () => {
    const [adsImg, setAdsImg] = useState('');
    const { lang } = useSelector(state => state.lang);
    const dispatch = useDispatch();

    const fetchAds = async () => {
        const res = await getMethod(
            `${POPUP_API}?app_name_id=${process.env.REACT_APP_ID}`
        );
        if (res.con) {
            setAdsImg(res.data?.popup_photo);
        }
    };
    useEffect(() => {
        fetchAds();
    }, []);

    return (
        adsImg && <div
            className={styles.modalBackdrop} style={{ display: lang ? 'flex' : 'none' }}>
            <motion.div
                initial={{ top: '-100px' }}
                animate={{ top: 0 }}
                transition={{ duration: 0.5 }}
                className={styles['ads-box']}>
                <FiX className={styles.closeIcon} onClick={() => dispatch(loginStatusHandler(false))} />
                <img alt='ads' src={adsImg} className={styles.adsImg} />
            </motion.div>
        </div>
    )
}

export default AdsPopup
