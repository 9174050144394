import React,{useEffect,useState} from 'react'
import Marquee from "react-fast-marquee";
import classes from '../assets/styles/BannerCaption.module.css';
import { getMethod } from '../services';
import {CAPTION_API} from '../services/constant';
import config from '../config/config.json';
const BannerCaption = () => {
  const [message,setMessage] = useState("မင်္ဂလာပါ");
  const fetchCaption = async () => {
  const res = await getMethod(`${CAPTION_API}?app_name_id=${config.app_name_id}`);
    if(res.con){
      setMessage(res.data?.caption);
    };
  };
  useEffect(()=>{
    fetchCaption();
  },[])
  return (
    <div className={classes['banner-frame']}>
        <Marquee 
            gradient={false}
            speed={50}
        >
            {message} ... &nbsp;
        </Marquee>
    </div>
  )
}

export default BannerCaption