import LocalizedStrings from 'react-localization';

export const langChange = new LocalizedStrings({
    mm:{
        login: "အကောင့်ဝင်ပါ",
        register: "အကောင့်သစ်ဖွင့်ပါ",
        loginTitle: "အကောင့်ဝင်ရန်",
        loginPhone: "ဖုန်းနံပါတ် ထည့်ပါ။",
        loginName: "အကောင့်နာမည် ထည့်ပါ။",
        loginPassword: "လျှို့ဝှက်နံပါတ်",
        home:"ပင်မစာမျက်နှာ",
        contact: "ဆက်သွယ်ရန်",
        contactus: "ဆက်သွယ်ရန်",
        loginContactText: "အခက်အခဲရှိလျှင် ဆက်သွယ်ရန်",
        step: "ကစားရန်အဆင့်",
        casino: "ကာစီနို",
        registerToday: "ဒီနေ့အကောင့်ဖွင့်ပါ",
        depositMoney: "ငွေဖြည့်ပါ",
        playNow: "ဂိမ်းကစားပါ",
        deposit: "ငွေသွင်း",
        withdraw: "ငွေထုတ်",
        depositForm: "ငွေသွင်း",
        withdrawForm: "ငွေထုတ်",
        depositAmount: "ငွေသွင်း ပမာဏ",
        withdrawAmount: "ငွေထုတ် ပမာဏ",
        transactionNo: "လုပ်ငန်းစဉ် နံပါတ်",
        accountNo: "အကောင့် နံပါတ်",
        confirm: "အတည်ပြုပါ",
        waiting: "စောင့်ဆိုင်းနေသည် ...",
        alreadyDeposit: "ငွေဖြည့်ထားသည်။ ကျေးဇူးပြု၍ အတည်ပြုမှုကိုစောင့်ပါ။",
        alreadyWithdraw: "ငွေထုတ်ထားသည်။ ကျေးဇူးပြု၍ အတည်ပြုမှုကိုစောင့်ပါ။",
        success: "အောင်မြင်သည်။",
        depositSuccess: "ငွေဖြည့်မှု အောင်မြင်သည်။",
        withdrawSuccess: "ငွေထုတ်မှု အောင်မြင်သည်။",
        fail: "မအောင်မြင်ပါ။",
        loginFirst: "ကျေးဇူးပြု၍ အကောင့်ဝင်ပါ။",
        logout: "ထွက်မည်",
        choosePayment: "ငွေထုတ်စနစ် ရွေးပါ။",
        bankAccountNumber: "ဘဏ်အကောင့်နံပါတ်",
        bankAccountName: "ဘဏ်အကောင့်နာမည်",
        chooseBank: "ဘဏ်အမျိုးအစားရွေးပါ",
        changepass: "စကားဝှက်ပြောင်းရန်",
        registerAcc: "အကောင့်သစ်ဖွင့်ရန်",
        oldpass: "စကားဝှက် အဟောင်း ထည့်ပါ။",
        newpass: "စကားဝှက် အသစ်ထည့်ပါ။",
        conpass: "စကားဝှက်(၈)လုံး အတည်ပြုပါ။",
        password: "စကားဝှက်(၈)လုံးထည့်ပါ။",
        forgetPass: "စကားဝှက်မေ့နေလား?",
        resetPass: "စကားဝှက် ပြန်ရှာရန်",
        acceptPayment: "ငွေပေးချေမှုများလက်ခံသည်",
        bankName: "ဘဏ်နာမည်",
        bankAccoutName: "ဘဏ်အကောင့်နာမည်",
        bankAccoutNumber: "ဘဏ်အကောင့်နံပါတ်",
        promotions: "ပရိုမိုးရှင်းများ",
        games: "ဂိမ်းများ",
        slipReview: "စလစ်သုံးသပ်ချက်",
        language: "ဘာသာစကား",
        playGame: "ဂိမ်းကစားရန်",
        all: "အားလုံး",
        sports: "အားကစား",
        slots: "စလော့",
        liveCasino: "ကာစီနိုတိုက်ရိုက်",
        fishingGames: "ငါးပစ်ဂိမ်း",
        passNotMatch: "စကားဝှက် ကိုက်ညီမှု မရှိပါ။",
        alreadyAcc: "အကောင့်ရှိပြီးသားလား?",
        fillInfo: "အချက်အလက်ဖြည့်ပါ",
        history: "မှတ်တမ်း",
        getOtp: "အတည်ပြုကုဒ် ရယူရန်",
        verifyOtp: "အတည်ပြုကုဒ် ထည့်ရန်",
        createPass: "စကားဝှက်အသစ် သတ်မှတ်ရန်",
        bettingHistory: 'ဂိမ်းမှတ်တမ်း',
        weAcceptPayment: 'ငွေသွင်းနိုင်သော ဘဏ်များ',
        remark: 'မှတ်ချက်',
        pleaseTransfer: 'ကျေးဇူးပြု၍ အထက်ပါအကောင့်များကိုသာ ငွေလွှဲပေးပါရန်။',
        next: 'ရှေ့ဆက်ရန်',
        amount: 'ပမာဏ',
        noData: 'မှတ်တမ်း မရှိပါ။',
        paymentAccNo: 'ငွေထုတ် အကောင့်နံပါတ် ထည့်ပါ။',
        conPaymentAccNo: 'ငွေထုတ် အကောင့်နံပါတ် အတည်ပြုပါ။',
        paymentAccName: 'ငွေထုတ် အကောင့်အမည် ထည့်ပါ။',
        no: 'စဉ်',
        payment: 'ငွေပေးချေမှု',
        status: 'အခြေအနေ',
        date: 'ရက်စွဲ',
        verify: 'အတည်ပြုပါ',
        welcomeFrom: 'Royal Casino မှ ကြိုဆိုပါတယ်။',
        welcomeBack: 'ပြန်လည် ကြိုဆိုပါတယ်။',
        loading: 'ခဏစောင့်ပါ။',
        myanmar: 'မြန်မာ',
        english: 'အင်္ဂလိပ်',
        confirmPhone: 'ဖုန်းနံပါတ် အတည်ပြုပါ။',
        from: 'မှ',
        to: 'အထိ',
        apply: 'ရယူမည်',
        moreInfo: 'အသေးစိတ်',
        close: 'ပိတ်မယ်',
        later: 'နောက်မှ',
        applied: 'ရယူပြိး',
        userNameMessage: '- အင်္ဂလိပ်အက္ခရာ(၃)လုံး၊ ဂဏန်း(၅)လုံး။\n - ဥပမာ - abc12345',
        loginSuccess: 'အကောင့်ဝင်ခြင်း အောင်မြင်ပါသည်။',
        registerSuccess: 'အကောင့်သစ်ဖွင့်ခြင်း အောင်မြင်ပါသည်။',
        depositLimit: 'အနည်းဆုံး (၁,၀၀၀)ကျပ်၊ အများဆုံး (၁,၀၀၀,၀၀၀)ကျပ်',
        withdrawLimit: 'အနည်းဆုံး (၁,၀၀၀)ကျပ်၊ အများဆုံး (၁,၀၀၀,၀၀၀)ကျပ်',
        minDeposit: 'အနည်းဆုံး (၁,၀၀၀)ကျပ်',
        minWithdraw: 'အနည်းဆုံး (၁,၀၀၀)ကျပ်',
        transactionNoLimit: 'လုပ်ငန်းစဉ်နံပါတ် နောက်ဆုံး(၆)လုံး ထည့်ပါ။',
        slip_image_is_required: 'ငွေလွှဲပုံ လိုအပ်ပါသည်။.',
        transaction_no_is_required: 'လုပ်ငန်းစဉ်နံပါတ် လိုအပ်ပါသည်။',
        transaction_no_must_be_6_digits: 'လုပ်ငန်းစဉ်နံပါတ် (၆)လုံး ဖြစ်ရပါမည်။',
        deposit_success: 'ငွေသွင်းခြင်း အောင်မြင်ပါသည်။',
        withdraw_success: 'ငွေထုတ်ခြင်း အောင်မြင်ပါသည်။',
        please_check_your_internet_connection: 'ကျေးဇူးပြု၍ သင်၏အင်တာနက်ကို စစ်ဆေးပေးပါ။',
        amount_field_is_required: 'ငွေပမာဏထည့်ရန် လိုအပ်ပါသည်။',
        payment_acc_name_is_required: 'ငွေထုတ်အကောင့်အမည် ထည့်ရန်လိုအပ်ပါသည်။',
        payment_is_required: 'ငွေထုတ်စနစ် ရွေးချယ်ရန်အပ်ပါသည်။',
        payment_acc_no_is_required: 'ငွေထုတ်အကောင့်နံပါတ် ထည့်ရန်လိုအပ်ပါသည်။',
        min_max_payment_acc_no: 'အနည်းဆုံးဂဏန်း (၉)လုံးမှ (၂၀)လုံး အတွင်းဖြစ်ရပါမည်။',
        confirm_payment_no_is_required: 'ငွေထုတ်အကောင့်အတည်ပြုနံပါတ် ထည့်ရန်လိုအပ်ပါသည်။',
        payment_confirm_must_be_match: 'ငွေထုတ်အကောင့်အတည်ပြုနံပါတ် တူညီရပါမည်။',
        confirm_phone_does_not_match: "ငွေထုတ်အတည်ပြုနံပါတ် မတူညီပါ။",
        name_is_required: 'အကောင့်အမည် ထည့်ရန်လိုအပ်ပါသည်။',
        password_is_required: 'စကားဝှက် ထည့်ရန်လိုအပ်ပါသည်။',
        confirm_password_is_required: 'စကားဝှက်အတည်ပြုရန် လိုအပ်ပါသည်။',
        password_must_be_at_least_8_characters: 'အနည်းဆုံး စကားဝှက်(၈)လုံး ဖြစ်ရပါမည်။',
        confirm_register_phone_does_not_match: 'အတည်ပြုဖုန်းနံပါတ် မတူညီပါ။',
        min_max_phone_no: 'အနည်းဆုံးဂဏန်း (၉)လုံးမှ (၂၀)လုံး အတွင်းဖြစ်ရပါမည်။',
        confirm_phone_no_is_required: 'အတည်ပြုဖုန်းနံပါတ် ထည့်ရန်လိုအပ်ပါသည်။',
        the_name_has_already_been_taken: 'အသုံးပြုသူအမည် ရှိပြီးဖြစ်ပါသည်။',
        the_phone_has_already_been_taken: 'ဖုန်းနံပါတ် ရှိပြီးဖြစ်ပါသည်။',
        phone_is_required: 'ဖုန်းနံပါတ်ထည့်ရန် လိုအပ်ပါသည်။',
        confirm_password_does_not_match: 'အတည်ပြုစကားဝှက် မတူညီပါ။',
        change_password_successful: 'စကားဝှက်ပြောင်းလဲခြင်း အောင်မြင်ပါသည်။',
        password_and_confirm_password_must_match: 'အတည်ပြုစကားဝှက် တူညီရမည်။',
        old_password_is_required: 'စကားဝှက်အဟောင်း ထည့်ရန်လိုအပ်ပါသည်။',
        new_password_is_required: 'စကားဝှက်အသစ် ထည့်ရန်လိုအပ်ပါသည်။',
    },
    
    en:{
        login: 'Login',
        register: "Register",
        loginTitle: "User Login",
        loginPhone: "Phone",
        loginName: "User Name",
        loginPassword: "Password",
        home:"Home",
        contact: "Contact",
        contactus: "Contact Us",
        loginContactText: "If there any problem please contact",
        step: "Step To Play",
        casino: "Casino",
        registerToday: "Register Today",
        depositMoney: "Deposit Money",
        playNow: "Play Game",
        deposit: "Deposit",
        withdraw: "Withdraw",
        depositForm: "Deposit Form",
        withdrawForm: "Withdraw Form",
        depositAmount: "Deposit Amount",
        withdrawAmount: "Withdraw Amount",
        transactionNo: "Slip Code",
        accountNo: "Account No",
        confirm: "Confirm",
        waiting: "Waiting ...",
        alreadyDeposit: "You have already deposit.Please wait approve!",
        alreadyWithdraw: "You have already withdraw.Please wait approve!",
        success: "Success!",
        depositSuccess: "Deposit Successful!",
        withdrawSuccess: "Withdraw Successful!",
        fail: "Fail!",
        loginFirst: "Login please!",
        logout: "Logout",
        choosePayment: "Choose Payment Type",
        bankAccountNumber: "Bank Account Number",
        bankAccountName: "Bank Account Name",
        chooseBank: "Choose Bank",
        changepass: "Change Password",
        registerAcc: "Register Account",
        oldpass: "Old Password",
        newpass: "New Password",
        conpass: "Confirm Password",
        password: "Password",
        forgetPass: "Forget Password?",
        resetPass: "Reset Password",
        acceptPayment: "We Accept Payment",
        bankName: "Bank Name",
        bankAccoutName: "Bank Account Name",
        bankAccoutNumber: "Bank Account Number",
        promotions: "Promotions",
        games: "Games",
        slipReview: "Slip Review",
        language: "Language",
        playGame: "Play Game",
        all: "All",
        sports: "Sports",
        slots: "Slots",
        liveCasino: "Live Casino",
        fishingGames: "Fishing Games",
        passNotMatch: "Your password does not match!",
        alreadyAcc: "Already have an account?",
        fillInfo: "Fill Information",
        history: "History",
        getOtp: "Get OTP",
        verifyOtp: "Verify OTP",
        createPass: "Create New Password",
        bettingHistory: 'Betting History',
        weAcceptPayment: 'We accept payment.',
        remark: 'Remark',
        pleaseTransfer: 'Please transfer only those account!',
        next: 'Next',
        amount: 'Amount',
        noData: 'No Data Found!',
        paymentAccNo: 'Payment account no.',
        conPaymentAccNo: 'Confrim payment account no.',
        paymentAccName: 'Payment account name.',
        no: 'No.',
        payment: 'Payment',
        status: 'Status',
        date: 'Date',
        verify: 'Verify',
        welcomeFrom: 'Welcome From Royal Casino!',
        welcomeBack: 'Welcome Back!',
        loading: 'Loading',
        myanmar: 'Myanmar',
        english: 'English',
        confirmPhone: 'Confirm Phone',
        from: 'FROM',
        to: 'TO',
        apply: 'APPLY',
        moreInfo: 'MORE INFO',
        close: 'CLOSE',
        later: 'LATER',
        applied: 'APPLIED',
        userNameMessage: 'The first three letters is A-Z and last five letters is 0-9 \n - ( Eg: abc12345 )',
        loginSuccess: 'Login Successful!',
        registerSuccess: 'Register Successful!',
        depositLimit: 'Minimum 1,000 & Maximum 1,000,000',
        withdrawLimit: 'Minimum 1,000 & Maximum 1,000,000',
        minDeposit: 'Minimum deposit 1,000.',
        minWithdraw: 'Minimum withdraw 1,100.',
        transactionNoLimit: 'Last 6 Transaction No.',
        slip_image_is_required: 'Slip image is required.',
        transaction_no_is_required: 'Transaction no. is required.',
        transaction_no_must_be_6_digits: 'Transaction no. must be 6 digits!',
        deposit_success: 'Deposit Successful!',
        withdraw_success: 'Withdraw Successful!',
        please_check_your_internet_connection: 'Please check your internet connection!',
        amount_field_is_required: 'Amount field is required!',
        payment_acc_name_is_required: 'Payment account name is required!',
        payment_is_required: 'Payment is required!',
        payment_acc_no_is_required: 'Payment account no. is required!',
        min_max_payment_acc_no: 'Payment account no. is minium 9 and maximum 20.',
        confirm_payment_no_is_required: 'Confirm payment account no. is required!',
        payment_confirm_must_be_match: 'Payment confimation and payment must be match!',
        confirm_phone_does_not_match: 'Confirm phone number does not match!',
        name_is_required: 'Name is required.',
        password_is_required: 'Password is required.',
        confirm_password_is_required: 'Confirm password is required!',
        password_must_be_at_least_8_characters: 'Password must be at least 8 characters!',
        confirm_register_phone_does_not_match: 'Confirm phone no. does not match!',
        min_max_phone_no: 'Phone no. is minimum 9 and maximum 20.',
        confirm_phone_no_is_required: 'Confirm phone no. is required!',
        the_name_has_already_been_taken: 'The name has already been taken.',
        the_phone_has_already_been_taken: 'The phone has already been taken.',
        phone_is_required: 'Phone is required!',
        confirm_password_does_not_match: 'Confirm password does not match!',
        change_password_successful: 'Change password successful!',
        password_and_confirm_password_must_match: 'Password and confirm password must match!',
        old_password_is_required: 'Old password is required!',
        new_password_is_required: 'New password is required!',
    }
});