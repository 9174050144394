import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import classes from '../assets/styles/BettingHistoryTable.module.css';
import { getMethod } from '../services';
import Loader from '../utils/Loader';
import SweetAlert from 'sweetalert2';
import NoData from '../utils/NoData';
import format from 'date-fns/format';
import { BETTING_HISTORY_API } from '../services/constant';

const BettingHistoryTable = () => {
  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState([]);

  const fetchHistory = async () => {
    const token = localStorage.getItem("r_c_auth");
    let currentDate = format(new Date(), "yyyy-MM-dd");
    console.log(currentDate)
    if (token) {
      setLoading(true);
      const deToken = JSON.parse(token);
      const res = await getMethod(
        `${BETTING_HISTORY_API}?user_id=${deToken.user_id}&start_date=${currentDate}&end_date=${currentDate}&app_name_id=${process.env.REACT_APP_ID}`,
        deToken.token
      );
      if (res) {
        setLoading(false);
        if (res === "Failed to fetch") {
          SweetAlert.fire({
            width: "330px",
            icon: "error",
            title: "Connection!",
            text: "Please check internet connection!",
            confirmButtonText: "Ok",
          });
        }
        if (res.con) {
          setHistory(res.data);
        }
      }
    }
  };

  useEffect(() => {
    fetchHistory();
  }, []);

  // sort by amount descending
  function sortByIdDescending(arr) {
    return arr.slice().sort((a, b) => b.id - a.id);
  }

  // for table scroll 
  const slider = document.querySelector('.own-scroll');
  if (slider) {
    let mouseDown = false;
    let startX, scrollLeft;
    let startDragging = function (e) {
      mouseDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    };
    let stopDragging = function (event) {
      mouseDown = false;
    };
    slider.addEventListener('mousemove', (e) => {
      e.preventDefault();
      if (!mouseDown) { return; }
      const x = e.pageX - slider.offsetLeft;
      const scroll = x - startX;
      slider.scrollLeft = scrollLeft - scroll;
    });
    slider.addEventListener('mousedown', startDragging, false);
    slider.addEventListener('mouseup', stopDragging, false);
    slider.addEventListener('mouseleave', stopDragging, false);
  }

  // for decimal cut 
  const chunk = num => {
    return Number(num.toFixed(2));
  };

  return (
    <>
      <div className={classes['auth-card-body']}>
        <div className={`own-scroll ${classes['table-card-container']}`} style={{cursor: 'grab'}}>
          <ul className={classes['table-header']}>
            <li><b>#</b></li>
            <li>Provider</li>
            <li>Bet</li>
            <li>Payout</li>
            <li>Profit</li>
            <li>Game Name</li>
            <li>Bet Time</li>
            <li>Bet Type</li>
            <li>Bet Status</li>
            <li>Win / Lose</li>
            <li>Status</li>
          </ul>
          {
            history.length > 0 ?
            sortByIdDescending(history).map((hist, i) =>
                <ul className={classes['table-header']} key={i}>
                  <li>{i + 1}.</li>
                  <li>{hist.site}</li>
                  <li style={{textAlign: 'end'}}>{hist.bet?.toLocaleString('en-us')}</li>
                  <li style={{textAlign: 'end'}}>{hist.payout?.toLocaleString('en-us')}</li>
                  <li style={{textAlign: 'end'}}>{hist.win_lose_amount ? chunk(hist.win_lose_amount)?.toLocaleString('en-us') : 0}</li>
                  <li>{hist.game_name ? hist.game_name : '--'}</li>
                  <li>{hist.start_time}</li>
                  <li>{hist.bet_type}</li>
                  <li><span className={`badge bg-${hist.bet_status === "Accept" ? "info" : hist.bet_status === "END" ? "success" : "danger"}`}>{hist.bet_status}
                  </span></li>
                  <li> <span className={`badge bg-${hist.win_lose === "LOSE" ? "danger" : hist.win_lose === "WIN" ? "success" : "info"}`}>{hist.win_lose}
                  </span></li>
                  <li style={{ color: hist.status === "Rejected" ? 'red' : 'green' }}>{hist.status}</li>
                </ul>
              )
              :
              <NoData />

          }
        </div>
      </div>
      {
        ReactDOM.createPortal(loading && <Loader />, document.getElementById('portal'))
      }
    </>
  )
}
export default BettingHistoryTable
