import React, { useEffect, useState } from 'react'
import classes from '../assets/styles/Header.module.css';
import { Link, useNavigate } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { controlSidebar } from '../store/slices/sidebarSlice';
import logo from '../assets/logo/logo.png';
import Button from '../utils/Button';
import profileImage from '../assets/images/profile.png';
import { getMethod } from '../services';
import { USER_INFO_API } from '../services/constant';
import { langChange } from '../lang';
const Header = () => {
  const {controlValue} = useSelector(state => state.sidebarControlSlice);
  const {logoutVal} = useSelector(state => state.logoutStatusSlice);
  const [data,setData] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sidebarControlHandler = () => {
    dispatch(controlSidebar(!controlValue));
  };

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => { }, [lang]);

  const fetchUserInfo = async () => {
      const token = localStorage.getItem('r_c_auth');
      if(token){
          const deToken = JSON.parse(token);
          let res = await getMethod(USER_INFO_API, deToken.token);
          if(res){
             if(res.message === "Unauthenticated."){
                  navigate('/login');
             };
             if(res.con){
                setData(res.data?.user_info);
             };
          };
      };
  };

  useEffect(()=>{
    fetchUserInfo();
  },[]);

  return (
    <>
      <div className={classes['header-container']}>
          <div className={classes['header-flex']}>
              <div className={classes['logo-flex']}>
                  <div className={classes['menu-icon']}>
                    <FiMenu onClick={sidebarControlHandler}/>
                  </div>
              </div>
              <div>
              <Link to="/">
                      <img src={logo} alt="logo" className={classes.logo}/>
              </Link>
              </div>
              {
                localStorage.getItem('r_c_auth')?
                    <div className={classes['profile']}>
                        <img src={profileImage} className={classes['profile-img']} />
                        <div>
                            <p className={classes['user-name']}>{data?.name ?? "--"}</p>
                            <p className={classes['user-balance']}>{data?.balance?.toLocaleString('en-us') ?? 0}</p>
                        </div>
                  </div>
                  :
                  <div className={classes['btn-group']}>
                      <Button label={langChange.login} type="button" bgColor="#6b67eb" bgHover="#333258" event={() => navigate('/login')}/>
                      {/* <Button label={langChange.register} type="button" bgColor="#5abc1c" bgHover="#344e22" event={() => navigate('/signup')}/> */}
                  </div>
              }
          </div>
      </div>
    </>
  )
}

export default Header
