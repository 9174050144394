import { createSlice } from "@reduxjs/toolkit";

const loginSlice = createSlice({
    name: 'login',
    initialState: {
        login: false,
    },
    reducers: {
        loginStatusHandler: (state, action) => {
            state.login = action.payload;
        }
    }
});

export const { loginStatusHandler } = loginSlice.actions;
export default loginSlice.reducer;