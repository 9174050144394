import React from 'react';
import classes from '../assets/styles/NoData.module.css';
import { langChange } from '../lang';

const NoData = () => {
  return (
    <div className={classes['nofount']}>
        {langChange.noData}
    </div>
  )
}

export default NoData
