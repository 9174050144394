import React, { useEffect } from 'react';
import main_class from '../assets/styles/Main.module.css';
import Header from '../layout/Header';
import FullSidebar from '../layout/FullSidebar';
import Sidebar from '../layout/Sidebar';
import { langChange } from '../lang';
import BettingHistoryTable from './BettingHistoryTable';
import classes from '../assets/styles/BettingHistory.module.css'
import { useSelector } from 'react-redux';

export default function BettingHistory() {
    const { lang } = useSelector(state => state.lang);
    useEffect(() => { }, [lang])

    return (
        <>
            <div className={main_class['container-main']}>
                <Header />
                <Sidebar />
                <FullSidebar />
                <>
                    <div className={classes['auth-card']}>
                        <div className={classes['auth-card-header']}>
                            <span className={classes.active}>{langChange.bettingHistory}</span>
                        </div>
                        <BettingHistoryTable />
                    </div>
                </>
            </div>
        </>
    )
}
