import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Header from '../layout/Header';
import Sidebar from '../layout/Sidebar';
import FullSidebar from '../layout/FullSidebar';
import ProviderGamesCard from '../utils/ProviderGamesCard';
import classes from '../assets/styles/GameList.module.css';
import ToggleInput from '../utils/ToggleInput';
import { useParams } from 'react-router-dom';
import { getMethod, postMethod } from '../services';
import { PROVIDER_API,GAMES_LIST_API, GAME_LAUNCH_API } from '../services/constant';
import Loader from '../utils/Loader';
import SweetAlert from 'sweetalert2';
import Pagination from '../utils/Pagination';
import { langChange } from '../lang';
import { useSelector } from 'react-redux';

const GameList = () => {
  const [loading,setLoading] = useState(true);
  const [provider,setProvider] = useState({});
  const [games,setGames] = useState([]);
  const [filterGames, setFilterGames] = useState([]);
  const [searchGames,setSearchGames] = useState([]);

  const [page,setPage] = useState(1);
  const [totalPage,setTotalPage] = useState(1);
  const [from,setFrom] = useState(1);
  const params = useParams();
  const [imageLoad,setImageLoad] = useState(true);

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => { }, [lang]);

  const fetchProvider = async () => {
     const res = await getMethod(`${PROVIDER_API}/${params.id}`);
     if(res){
       setLoading(false);
        if(res === 'Failed to fetch'){
            SweetAlert.fire({
              width: '330px',
              icon: 'error',
              title: 'Connection!',
              text: langChange.please_check_your_internet_connection,
              confirmButtonText: 'Ok',
            });
        };
        if(res.con){
          setProvider(res.data);
        }
     };
  };
  useEffect(()=> {
    fetchProvider();
  },[]);

  const fetchGames = async () => {
      setLoading(true)
      let res = await getMethod(`${GAMES_LIST_API}?system_status=active&website=true&g_type=${params.name}&p_code=${provider?.name}&sortColumn=g_type&page=${page}&limit=500`);
      if(res){
        setLoading(false);
         if(res.con){
            // setGames(prev => [...prev,...res.data]);
            setGames(res.data);
            setFilterGames([]);
            setSearchGames("");
            setFrom(res?.meta?.from)
            setTotalPage(res?.meta?.last_page);
         };
      };
  };

  useEffect(()=> {
      fetchGames();
  },[provider,page]);

  const searchGameHandler = (e) => {
    let str = e.target.value;
    setSearchGames(e.target.value);
    let arr = str.split(" ");
    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    let str2 = arr.join(" ");
    let temp = [];
    if(games.length > 0){
        games.map((el) => {
            if(el.gameName.startsWith(str2)){
                temp.push(el);
            }
        })
    };
    setFilterGames(temp);
};
const launch = async (name,type,code,newPara) => {
    setLoading(true);
    const token = localStorage.getItem('r_c_auth');
    if(token){
        const deToken = JSON.parse(token);
        const data = {
            provider_name: name,
            p_code: name,
            p_type: type,
            g_code: code,
            h5: newPara
        };
        const res = await postMethod(GAME_LAUNCH_API, data, deToken.token);
        if(res){
          if(res === 'Failed to fetch'){
            SweetAlert.fire({
              width: '330px',
              icon: 'error',
              title: 'Connection!',
              text: langChange.please_check_your_internet_connection,
              confirmButtonText: 'Ok',
            });
          };
          setLoading(false);
            if(res.con){
              if(res.data.gameUrl){
                  if(!window.open(res.data.gameUrl)) {
                      window.location.href = res.data.gameUrl;
                  };
              };
            }
        }

    }

};



const launchGame = (name,type,code,h5) => {
    let newPara = h5 === "mobile"? 1: 0;
    launch(name,type,code,newPara);
};

  return (
    <>
      <div className={classes['container-main']}>
          <Header />
          <Sidebar/>
          <FullSidebar />

          <div className={classes['provider-cards-list']}>
              <div className={classes['games-header']}>
                    <div className={classes['game-header-flex']}>
                        <div className={classes.totalGame}>
                          <img src={provider?.photo}/>
                          <span> Total - {games?.length}</span>
                        </div>
                        <ToggleInput event={searchGameHandler}/>
                    </div>
                    {
                      totalPage > 1 &&
                          <div className={classes['pagi-position']}>
                              <Pagination 
                                  setPage={setPage} 
                                  totalPage={totalPage}
                                  setLoading={setLoading}
                                />
                          </div>
                    }
              </div>
              <ul className={classes['provider-card-flex']}>
                  {
                    searchGames.length > 0 ?
                    filterGames.length > 0 &&
                    filterGames.map((game,i) =>
                        <ProviderGamesCard key={i} name={`${game.gameName.slice(0, 10)} ...`} image={game.imgFileName} type={true} event={() => launchGame(game.p_code,game.p_type,game.g_code,game.h5)}/>
                    )
                    :
                    games.length > 0 &&
                    games.map((game,i) => 
                        <ProviderGamesCard key={i} name={`${game.gameName.slice(0, 10)} ...`} image={game.imgFileName} type={true} lazy={true} imageLoad={imageLoad} setImageLoad={setImageLoad} event={() => launchGame(game.p_code,game.p_type,game.g_code,game.h5)} />
                    )
                  }
              </ul>
          </div>
      </div>
      {
        ReactDOM.createPortal(loading && <Loader />, document.getElementById('portal'))
      }
    </>
  )
}

export default GameList
