import React, { useState } from 'react'
import { FiClock, FiGift, FiGlobe, FiHome, FiLogOut, FiMenu, FiUnlock } from 'react-icons/fi';
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi';
import { Tooltip } from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { controlSidebar } from '../store/slices/sidebarSlice';
import { logoutStatusHandler } from '../store/slices/logoutSlice';
import { motion } from "framer-motion"
import classes from '../assets/styles/Sidebar.module.css';
import { useNavigate } from 'react-router-dom';
import { langChange } from '../lang';
import { langTypeHandler } from '../store/slices/langSlice';

const Sidebar = () => {
  const { controlValue } = useSelector(state => state.sidebarControlSlice);
  const { logoutVal } = useSelector(state => state.logoutStatusSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sidebarControlHandler = () => {
    dispatch(controlSidebar(!controlValue));
  };
  const { lang } = useSelector(state => state.lang)
  const logoutHandler = () => {
    const token = localStorage.getItem('r_c_auth');
    if (token) {
      localStorage.removeItem('r_c_auth');
      navigate('/login');
      dispatch(logoutStatusHandler(!logoutVal));
    } else {
      navigate('/login');
      dispatch(logoutStatusHandler(!logoutVal));
    };
  };

  const [langChangeModalOpen, setLangChangeModalOpen] = useState(false);

  const langHandler = (type) => {
    localStorage.setItem("_r_c_lang", type);
    langChange.setLanguage(type);
    dispatch(langTypeHandler(type));
  };

  const responsive = window.matchMedia("(max-width: 1024px)");
  return (
    <>
      {
        !responsive.matches &&
        <motion.aside className={classes['sidebar-position']}
          initial={{ left: '0' }}
          animate={{ left: controlValue ? '-100%' : '0' }}
          transition={{ duration: 0.5 }}
        >
          <ul className={classes['sidebar-container']}>
            <li className={`${classes['sidebar-icon']} ${classes['menu-icon']}`} onClick={sidebarControlHandler}>
              <FiMenu />
            </li>
            <li className={classes['sidebar-icon']} onClick={() => navigate('/')}>
              <FiHome id="home-tooltip" style={{ outline: 'none' }} />
              <Tooltip style={{ fontSize: '15px' }} anchorSelect="#home-tooltip" content={langChange.home} place="right" />
            </li>
            {
              localStorage.getItem('r_c_auth') &&
              <>
                {/* <li className={classes['sidebar-icon']} onClick={() => navigate('/deposit')}>
                  <GiPayMoney id='deposit-tooltip' style={{ outline: 'none' }} />
                  <Tooltip style={{ fontSize: '15px' }} anchorSelect="#deposit-tooltip" content={langChange.deposit} place="right" />
                </li>
                <li className={classes['sidebar-icon']} onClick={() => navigate('/withdraw')}>
                  <GiReceiveMoney id='withdraw-tooltip' style={{ outline: 'none' }} />
                  <Tooltip style={{ fontSize: '15px' }} anchorSelect="#withdraw-tooltip" content={langChange.withdraw} place="right" />
                </li> */}
                <li className={classes['sidebar-icon']} onClick={() => navigate('/betting-history')}>
                  <FiClock id='betting-tooltip' style={{ outline: 'none' }} />
                  <Tooltip style={{ fontSize: '15px' }} anchorSelect="#betting-tooltip" content={langChange.bettingHistory} place="right" />
                </li>
              </>}
            <li className={classes['sidebar-icon']} onClick={() => navigate('/promotions')}>
              <FiGift id="promo-tooltip" style={{ outline: 'none' }} />
              <Tooltip style={{ fontSize: '15px' }} anchorSelect="#promo-tooltip" content={langChange.promotions} place="right" />
            </li>
            {
              localStorage.getItem('r_c_auth') &&
              <li className={classes['sidebar-icon']} onClick={() => navigate('/change-password')}>
                <FiUnlock id='change-pass-tooltip' style={{ outline: 'none' }} />
                <Tooltip style={{ fontSize: '15px' }} anchorSelect="#change-pass-tooltip" content={langChange.changepass} place="right" />
              </li>
            }
            <li className={classes['sidebar-icon']} style={{ position: 'relative' }} onMouseEnter={() => setLangChangeModalOpen(true)} onMouseLeave={() => setLangChangeModalOpen(false)}>
              <FiGlobe style={{ outline: 'none' }} />
              {
                langChangeModalOpen && <div style={{ position: 'absolute', top: 15, right: '-95px', background: '#ddd', padding: 5, flexDirection: 'column', borderRadius: 5 }}>
                  <span className={`${classes['lang-text']} ${lang === 'mm' && classes['lang-active']}`} onClick={() => { langHandler('mm') }}>{`Myanmar`}</span> <br />
                  <span className={`${classes['lang-text']} ${lang === 'en' && classes['lang-active']}`} onClick={() => { langHandler('en') }}>{`English`}</span>
                </div>
              }
            </li>
            {
              localStorage.getItem('r_c_auth') && <>
                <li className={classes['sidebar-icon']} onClick={logoutHandler}>
                  <FiLogOut id='logout-tooltip' style={{ outline: 'none' }} />
                  <Tooltip style={{ fontSize: '15px' }} anchorSelect="#logout-tooltip" content={langChange.logout} place="right" />
                </li>
              </>
            }


          </ul>
        </motion.aside>
      }
    </>
  )
}

export default Sidebar
