import React, { useEffect } from 'react';
import Signin from './Signin';
import classes from '../assets/styles/Auth.module.css';
import Signup from './Signup';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo/logo.png'
import { langChange } from '../lang';
import { useSelector } from 'react-redux';

const Auth = () => {
  const navigate = useNavigate();
  const url = window.location.pathname;

  useEffect(()=>{
    const token = localStorage.getItem('r_c_auth')
    token && navigate('/')
  },[])

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => { }, [lang]);
  return (
    <div className={classes['container-main']}>
      <div className={classes['logo-position']}>
           <img src={logo} alt="logo" className={classes.logo} onClick={()=> navigate('/')} />
      </div>
      <div className={classes['auth-card']}>
            <div className={classes['auth-card-header']}>
                 <span className={`${url === '/login' && classes.active}`} onClick={()=> navigate('/login')}>{langChange.login}</span>
                 {/* <span className={`${url === '/signup' && classes.active}`} onClick={()=> navigate('/signup')}>{langChange.register}</span> */}
            </div>
            {
              // url === '/signup'?
              // <Signup /> :
              <Signin />
            }
      </div>
    </div>
  )
}

export default Auth
