import React, { useEffect } from 'react'
import classes from '../assets/styles/Footer.module.css';
import { FaFacebookF, FaPhoneAlt, FaTelegram, FaViber } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { langChange } from '../lang';
import { useSelector } from 'react-redux';
const Footer = () => {
  const { lang } = useSelector((state) => state.lang);
  useEffect(() => { }, [lang]);
  return (
    <footer className={classes['footer']}>
        <p className={classes['payment-title']}>{langChange.weAcceptPayment}</p>
        <ul className={classes['payment-flex']}>
            <li className={classes['payment-card']}>
                <div className={classes['payment-card-container']}>
                    <img src='https://www.kbzpay.com/wp-content/uploads/sites/9/2020/04/blue-L.png' className={classes['payment-img']} />
                    <p className={classes['payment-text']}>KBZ Pay</p>
                </div>
            </li>
            <li className={classes['payment-card']}>
                <div className={classes['payment-card-container']}>
                    <img src='https://play-lh.googleusercontent.com/rPq4GMCZy12WhwTlanEu7RzxihYCgYevQHVHLNha1VcY5SU1uLKHMd060b4VEV1r-OQ' className={classes['payment-img']} />
                    <p className={classes['payment-text']}>Wave Pay</p>
                </div>
            </li>
        </ul>
        <p className={classes['payment-title']}>{langChange.contactus}</p>
        <ul className={classes['contactus-flex']}>
             <li className={classes['contactus-list']}>
               <Link to="tel: +95 9768688828" target='_blank'>
                  <div className={`${classes['contactus-logo']} ${classes['phone-h']}`}>
                       <FaPhoneAlt />
                  </div>
               </Link>
             </li>
             <li className={classes['contactus-list']}>
               <Link to="viber://chat?number=09768688828" target='_blank'>
                  <div className={`${classes['contactus-logo']} ${classes['viber-h']}`}>
                       <FaViber />
                  </div>
               </Link>
             </li>
             <li className={classes['contactus-list']}>
             {/* https://www.facebook.com/profile.php?id=100091109536948&mibextid=LQQJ4d */}
               <Link to="#" target='_blank'>
                  <div className={`${classes['contactus-logo']} ${classes['facebook-h']}`}>
                       <FaFacebookF />
                  </div>
               </Link>
             </li>
             <li className={classes['contactus-list']}>
               <Link to="https://t.me/+959768688828" target='_blank'>
                  <div className={`${classes['contactus-logo']} ${classes['facebook-h']}`}>
                       <FaTelegram />
                  </div>
               </Link>
             </li>
        </ul>
        <p className={classes['copyright-text']}>Copyright 2023 &copy; by Royal Casino</p>
    </footer>
  )
}

export default Footer
