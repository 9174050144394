import React, { useEffect, useState } from 'react';
import ReactDOM  from 'react-dom';
import classes from '../assets/styles/ProviderLists.module.css';
import { Link, useNavigate } from 'react-router-dom';
import ProviderGamesCard from '../utils/ProviderGamesCard';
import { getMethod } from '../services';
import { PROVIDER_API, PROVIDER_CONNECT_API } from '../services/constant';
import Loader from '../utils/Loader';
import SweetAlert from 'sweetalert2';
import { langChange } from '../lang';
import { useSelector } from 'react-redux';

const ProviderLists = () => {
    const [loading,setLoading] = useState(true);
    const [providerConnectLists,setProviderConnectLists] = useState([]);
    const [providers,setProviders] = useState([]);
    const [filterProviders,setFilterProviders] = useState([]);

    const [allActive,setAllActive] = useState(null);
    const [active,setActive] = useState(null);
    const navigate = useNavigate();

    const { lang } = useSelector((state) => state.lang);
    useEffect(() => { }, [lang]);

    const fetchProviderConnect = async () => {
         const res = await getMethod(`${PROVIDER_CONNECT_API}?status=active&website=true`);
         if(res){
             setLoading(false);
             if(res === 'Failed to fetch'){
                SweetAlert.fire({
                  width: '330px',
                  icon: 'error',
                  title: 'Connection!',
                  text: langChange.please_check_your_internet_connection,
                  confirmButtonText: 'Ok',
                });
            }
            if(res.con){
                setProviderConnectLists(res.data);
                setAllActive('all');
                setActive(null);
            }
         };
    };

    const fetchProvider = async () => {
        const res = await getMethod(`${PROVIDER_API}?status=active&website=true`);
        if(res){
            setLoading(false);
            if(res.con){
                setProviders(res.data);
                setAllActive('all');
                setActive(null);
                setFilterProviders([]);
            }
        };
    };
    useEffect(() => {
        fetchProviderConnect();
        fetchProvider();
    },[]);

    const providerConnectHandler = (list) => {
        setAllActive(null);
        setActive(list.name);
        setProviders([]);
        setFilterProviders(list.types);
    };
    const allProviderConnectHandler = () => {
        setLoading(true);
        fetchProvider();
    };

    const gamesListHandler = provider => {
        if(provider){
            navigate(`/${provider.type?provider.type.toLowerCase(): "all"}/${provider.provider_id?provider.provider_id:provider.id}`);
        };
    };

  return (
  <>
    <nav className={classes['provider-lists-group']}>
        <ul className={classes.providers}>
            <li className={`${allActive === 'all' && classes.active}`} onClick={allProviderConnectHandler}>
                <Link>All</Link>
            </li>
            {
                providerConnectLists.length > 0 &&
                providerConnectLists.map((list,i) =>
                    <li key={i} className={`${active === list.name && classes.active}`} onClick={() => providerConnectHandler(list)}>
                        <Link>{list.name}</Link>
                    </li>
                )
            }
        </ul>
    </nav>
    <div className={classes['provider-cards-list']}>
         <h1 className={classes['title']}>Providers</h1>
         <ul className={classes['provider-card-flex']}>
            {
                allActive === "all"?
                providers.length > 0 &&
                providers.map((provider,i) => 
                    <ProviderGamesCard name={provider?.full_name} image={provider?.photo} key={i} event={e => gamesListHandler(provider)}/>
                )
                :
                filterProviders.length > 0 && 
                filterProviders.map((provider,i) => 
                    <ProviderGamesCard name={provider?.provider?.full_name} image={provider?.provider?.photo} key={i} event={e => gamesListHandler(provider)}/>
                )
            }
             
         </ul>
    </div>
    {
        ReactDOM.createPortal(loading && <Loader />, document.getElementById('portal'))
    }
  </>
  )
}

export default ProviderLists
