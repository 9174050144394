import React, { useEffect, useState } from 'react';
import classes from '../assets/styles/ChangePassword.module.css';
import Button from '../utils/Button';
import { useNavigate } from 'react-router-dom';
import { postMethod } from '../services';
import Input from '../utils/Input';
import logo from '../assets/logo/logo.png'
import { CHANGE_PASSWORD_API } from '../services/constant';
import SweetAlert from 'sweetalert2';
import { langChange } from '../lang';
import { useSelector } from 'react-redux';

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => { }, [lang]);

  const [errorOldPassword, setErrorOldPassword] = useState("");
  const [errorNewPassword, setErrorNewPassword] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState("");
  const navigate = useNavigate();

  const oldPasswordHandler = e => {
    if (e.target.value.length > 8) {
      return;
    };
    const numericValue = e.target.value.replace(/\D/g, "");
    e.target.value = numericValue;
    setOldPassword(e.target.value);
    setErrorOldPassword("");
  };

  const newPasswordHandler = e => {
    if (e.target.value.length > 8) {
      return;
    };
    const numericValue = e.target.value.replace(/\D/g, "");
    e.target.value = numericValue;
    setNewPassword(e.target.value);
    setErrorNewPassword("");
  };

  const confirmPasswordHandler = e => {
    if (e.target.value.length > 8) {
      return;
    };
    const numericValue = e.target.value.replace(/\D/g, "");
    e.target.value = numericValue;
    setConfirmPassword(e.target.value);
    setErrorConfirmPassword("");
  }

  const changePasswordHandler = async e => {
    e.preventDefault();
    if (oldPassword) {
      if (newPassword) {
        if (confirmPassword) {
          if (newPassword === confirmPassword) {
            setLoading(true);
            const token = localStorage.getItem('r_c_auth');
            const data = {
              old_password: oldPassword,
              password: newPassword,
              password_confirmation: confirmPassword
            };
            if (token) {
              const deToken = JSON.parse(token);
              const res = await postMethod(CHANGE_PASSWORD_API, data, deToken.token);
              if (res) {
                setLoading(false);
                if (res === 'Failed to fetch') {
                  SweetAlert.fire({
                    width: '330px',
                    icon: 'error',
                    title: 'Connection!',
                    text: langChange.please_check_your_internet_connection,
                    confirmButtonText: 'Ok',
                  });
                }
                if (res.con) {
                  SweetAlert.fire({
                    width: '330px',
                    icon: 'success',
                    title: 'Success!',
                    text: langChange.change_password_successful,
                    confirmButtonText: 'Ok',
                  }).then(result => {
                    if (result.isConfirmed) {
                      localStorage.removeItem('r_c_auth');
                      navigate('/login');
                    };
                  });
                };
              }
            } else {
              navigate('/login');
            }
          } else {
            setErrorConfirmPassword(langChange.password_and_confirm_password_must_match)
          }
        } else {
          setErrorConfirmPassword(langChange.confirm_password_is_required)
        }
      } else {
        setErrorNewPassword(langChange.new_password_is_required)
      }
    } else {
      setErrorOldPassword(langChange.old_password_is_required)
    }

  }

  return (
    <div className={classes['container-main']}>
      <div className={classes['logo-position']}>
        <img src={logo} alt="logo" className={classes.logo} onClick={() => navigate('/')} />
      </div>
      <div className={classes['auth-card']}>
        <div className={classes['auth-card-header']}>
          {langChange.changepass}
        </div>
        <div className={classes['auth-card-body']}>
          <form onSubmit={changePasswordHandler}>
            <Input type="password" placeholder={langChange.oldpass} value={oldPassword} event={oldPasswordHandler} error={errorOldPassword} />
            <Input type="password" placeholder={langChange.newpass} value={newPassword} event={newPasswordHandler} error={errorNewPassword} />
            <Input type="password" placeholder={langChange.conpass} value={confirmPassword} event={confirmPasswordHandler} error={errorConfirmPassword} />
            <div className={classes['forgotpass-submit']}>
              <Button label={langChange.confirm} type="submit" bgColor="#5abc1c" bgHover="#344e22" loading={loading} />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ChangePassword
