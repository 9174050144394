import React, { useState } from 'react'
import { FiClock, FiGift, FiGlobe, FiHome, FiLogOut, FiMenu, FiUnlock, FiX } from 'react-icons/fi';
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi';
import { motion } from "framer-motion"
import { useDispatch, useSelector } from 'react-redux';
import { controlSidebar } from '../store/slices/sidebarSlice';
import { logoutStatusHandler } from '../store/slices/logoutSlice';
import classes from '../assets/styles/FullSidebar.module.css';
import { useNavigate } from 'react-router-dom';
import { langChange } from '../lang';
import { langTypeHandler } from '../store/slices/langSlice';
import Button from '../utils/Button';

const FullSidebar = () => {
    const { controlValue } = useSelector(state => state.sidebarControlSlice);
    const { logoutVal } = useSelector(state => state.logoutStatusSlice);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [langChangeModalOpen, setLangChangeModalOpen] = useState(false);
    const { lang } = useSelector(state => state.lang);

    const langHandler = (type) => {
        localStorage.setItem("_r_c_lang", type);
        langChange.setLanguage(type);
        dispatch(langTypeHandler(type));
    };

    const sidebarControlHandler = () => {
        dispatch(controlSidebar(!controlValue));
    };
    const logoutHandler = () => {
        const token = localStorage.getItem('r_c_auth');
        if (token) {
            localStorage.removeItem('r_c_auth');
            navigate('/login');
            dispatch(controlSidebar(!controlValue));
            dispatch(logoutStatusHandler(!logoutVal));
        } else {
            navigate('/login');
            dispatch(controlSidebar(!controlValue));
            dispatch(logoutStatusHandler(!logoutVal));
        };
    };

    return (
        <>
            <motion.aside className={classes['sidebar-position']}
                initial={{ left: '-200%' }}
                animate={{ left: controlValue ? '0' : '-200%' }}
                transition={{ duration: 0.5 }}
            >
                <ul className={classes['sidebar-container']}>
                    <li className={`${classes['sidebar-icon']} ${classes['menu-icon']}`}>
                        <div className={classes['menu-dis']}>
                            {
                                controlValue ?
                                    <FiX onClick={sidebarControlHandler} />
                                    :
                                    <FiMenu onClick={sidebarControlHandler} />
                            }
                        </div>
                    </li>
                    <li className={classes['sidebar-icon']} link="/" onClick={() => { navigate('/'); sidebarControlHandler() }}>
                        <div className={classes['icon-margin']}>
                            <FiHome />
                        </div>
                        <div className={classes['hover-underline']}>
                            <span className={classes['sidebar-text']}>{langChange.home}</span>
                        </div>
                    </li>
                    {
                        localStorage.getItem('r_c_auth') &&
                        <>
                            {/* <li className={classes['sidebar-icon']} onClick={() => { navigate('/deposit'); sidebarControlHandler() }}>
                                <div className={classes['icon-margin']}>
                                    <GiPayMoney />
                                </div>
                                <div className={classes['hover-underline']}>
                                    <span className={classes['sidebar-text']}>{langChange.deposit}</span>
                                </div>
                            </li>
                            <li className={classes['sidebar-icon']} onClick={() => { navigate('/withdraw'); sidebarControlHandler() }}>
                                <div className={classes['icon-margin']}>
                                    <GiReceiveMoney />
                                </div>
                                <div className={classes['hover-underline']}>
                                    <span className={classes['sidebar-text']}>{langChange.withdraw}</span>
                                </div>
                            </li> */}
                            <li className={classes['sidebar-icon']} onClick={() => { navigate('/betting-history'); sidebarControlHandler() }}>
                                <div className={classes['icon-margin']}>
                                    <FiClock />
                                </div>
                                <div className={classes['hover-underline']}>
                                    <span className={classes['sidebar-text']}>{langChange.bettingHistory}</span>
                                </div>
                            </li>
                        </>}
                    <li className={classes['sidebar-icon']} link="/" onClick={() => { navigate('/promotions'); sidebarControlHandler() }}>
                        <div className={classes['icon-margin']}>
                            <FiGift />
                        </div>
                        <div className={classes['hover-underline']}>
                            <span className={classes['sidebar-text']}>{langChange.promotions}</span>
                        </div>
                    </li>
                    {
                        localStorage.getItem('r_c_auth') &&
                        <li className={classes['sidebar-icon']} onClick={() => { navigate('/change-password'); sidebarControlHandler() }}>
                            <div className={classes['icon-margin']}>
                                <FiUnlock />
                            </div>
                            <div className={classes['hover-underline']}>
                                <span className={classes['sidebar-text']}>{langChange.changepass}</span>
                            </div>
                        </li>
                    }
                    <li className={classes['sidebar-icon']} style={{ position: 'relative' }} onMouseEnter={() => setLangChangeModalOpen(true)} onMouseLeave={() => setLangChangeModalOpen(false)}>
                        <div className={classes['icon-margin']}>
                            <FiGlobe />
                        </div>
                        <div className={classes['hover-underline']}>
                            <span className={classes['sidebar-text']}>{langChange.language}</span>
                        </div>
                        {
                            langChangeModalOpen && <div style={{ position: 'absolute', top: 20, right: 10, background: '#ddd', padding: 5, flexDirection: 'column', borderRadius: 5 }}>
                                <span className={`${classes['lang-text']} ${lang === 'mm' && classes['lang-active']}`} onClick={() => { langHandler('mm') }}>{`Myanmar`}</span> <br />
                                <span className={`${classes['lang-text']} ${lang === 'en' && classes['lang-active']}`} onClick={() => { langHandler('en') }}>{`English`}</span>
                            </div>
                        }
                    </li>
                    {
                        localStorage.getItem('r_c_auth') &&
                        <li className={classes['sidebar-icon']} onClick={logoutHandler}>
                            <div className={classes['icon-margin']}>
                                <FiLogOut />
                            </div>
                            <div className={classes['hover-underline']}>
                                <span className={classes['sidebar-text']}>{langChange.logout}</span>
                            </div>
                        </li>
                    }

                    {
                        localStorage.getItem('r_c_auth') ? "" : <li className={`${classes['sidebar-icon']} ${classes.responsiveToggle}`}>
                            <div className={classes['btn-group']}>
                                <Button setting={{ width: '100%', marginBottom: '10px' }} label={langChange.login} type="button" bgColor="#6b67eb" bgHover="#333258" event={() => navigate('/login')} />
                                {/* <Button setting={{ width: '100%', marginBottom: '10px' }} label={langChange.register} type="button" bgColor="#5abc1c" bgHover="#344e22" event={() => navigate('/signup')} /> */}
                            </div>
                        </li>
                    }
                </ul>
            </motion.aside>
            <motion.div
                initial={{ display: 'none' }}
                animate={{ display: controlValue ? 'block' : 'none' }}
                transition={{ duration: 0.5 }}
                className={classes['sidebar-backdrop']} onClick={sidebarControlHandler}></motion.div>
        </>
    )
}

export default FullSidebar
