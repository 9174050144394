import React, { useState } from 'react';
import classes from '../assets/styles/ProviderGamesCard.module.css';
import {FaPlay} from 'react-icons/fa';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import imageLoader from '../assets/images/preloader.png';

const ProviderGamesCard = ({name,image,event,type = false,lazy = false,imageLoad,setImageLoad}) => {
  
  return (
      <>
        {
          type?
          <li className={classes['card']}>
            <div className={classes['card-list']}>
                  {
                    lazy?
                    <LazyLoadImage
                      className={classes['provider-img']}
                      alt=""
                      effect="blur"
                      src={imageLoad ? imageLoader:image}
                      afterLoad={() => setImageLoad(false)}
                      beforeLoad={() => setImageLoad(true)}/>
                          :
                    <img className={classes['provider-img']} src={image}/>
                  }
                  <div className={classes['go-game-list']}>
                      <span className={classes['play-icon']}>
                            <FaPlay onClick={event}/>
                      </span>
                  </div>
            </div>
            <p className={classes['provider-text']}>{name}</p>
        </li>
        :
        <li className={classes['card']} onClick={event}>
            <div className={classes['card-list']}>
                {
                  lazy? 
                  <LazyLoadImage
                      className={classes['provider-img']}
                      alt=""
                      effect="blur"
                      src={imageLoad ? imageLoader: image}
                      afterLoad={() => setImageLoad(false)}
                      beforeLoad={() => setImageLoad(true)}/>
                  :
                  <img className={classes['provider-img']} src={image}/>
                }
            </div>
            <p className={classes['provider-text']}>{name}</p>
        </li>
        }
      </>
  )
}

export default ProviderGamesCard
