import { createSlice } from "@reduxjs/toolkit";

const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState: {
        controlValue: false
    },
    reducers: {
        controlSidebar: (state,action) => {
            state.controlValue = action.payload;
        }
    }
});

export const {controlSidebar} = sidebarSlice.actions;
export default sidebarSlice.reducer;