import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper";
import  '../assets/styles/BannerSlide.css';
import {getMethod} from '../services';
import { BANNER_API } from '../services/constant';
import config from '../config/config.json';

const BannerSlice = () => {
  const [banners,setBanners] = useState([]);
  const fetchBanner = async () => {
      const res = await getMethod(`${BANNER_API}?app_name_id=${config.app_name_id}`);
      if(res.con){
        setBanners(res.data);
      }
  };
  useEffect(()=>{
    fetchBanner();
  },[]);
  return (
    <Swiper
        spaceBetween={30}
        pagination={{
        clickable: true,
        }}
        modules={[Pagination,Autoplay]}
        className="mySwiper"
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        loop={true}
        speed={4000}
    >
     {
        banners.length > 0 &&
        banners.map((banner,i) => 
          <SwiperSlide key={i}>
            <img className={'banner-img'} src={banner.photo} />
          </SwiperSlide>
        )
     }
    </Swiper>
  )
}

export default BannerSlice
