import React, { useEffect, useState } from 'react';
import classes from '../assets/styles/ForgotPassword.module.css';
import Button from '../utils/Button';
import { Link, useNavigate } from 'react-router-dom';
import Input from '../utils/Input';
import logo from '../assets/logo/logo.png'
import { cleanUserDataHandler, userDataHandler } from '../store/slices/registerSlice';
import { useDispatch, useSelector } from 'react-redux';
import {postMethod} from '../services';
import { PASSWORD_CREATE_OTP_API } from '../services/constant';
import SweetAlert from 'sweetalert2';
import config from '../config/config.json';
import { langChange } from '../lang';

const ForgotPassword = () => {
  const [loading,setLoading] = useState(false);
  const [phone,setPhone] = useState("");
  const [errorPhone,setErrorPhone] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => { }, [lang]);

  useEffect(()=>{
    const token = localStorage.getItem('r_c_auth')
    token && navigate('/')
  },[])

  const phoneHandler = e => {
     setPhone(e.target.value);
     setErrorPhone("");
  };
  const forgetSubmit = async e => {
     e.preventDefault();
     if(phone){
        if(phone.length >= 9 && phone.length <= 20){
          setLoading(true);
            const res = await postMethod(PASSWORD_CREATE_OTP_API, {phone,app_name_id: config.app_name_id});
            if(res){
              setLoading(false);
              if(res === 'Failed to fetch'){
                SweetAlert.fire({
                  width: '330px',
                  icon: 'error',
                  title: 'Connection!',
                  text: langChange.please_check_your_internet_connection,
                  confirmButtonText: 'Ok',
                });
              }
              if(res.con){
                dispatch(userDataHandler({phone}));
                navigate('/verify/password');
              }else{
                if(res?.data?.phone){
                  setErrorPhone(res.data.phone[0]);
                };
              };
            }
        }else{
          setErrorPhone(langChange.min_max_phone_no);
        }
     }else{
        setErrorPhone(langChange.phone_is_required)
     };
  };

  useEffect(()=>{
    dispatch(cleanUserDataHandler());
  },[])
  return (
    <div className={classes['container-main']}>
      <div className={classes['logo-position']}>
           <img src={logo} alt="logo" className={classes.logo} onClick={()=> navigate('/')}/>
      </div>
      <div className={classes['auth-card']}>
            <div className={classes['auth-card-header']}>
              {langChange.forgetPass}
            </div>
            <div className={classes['auth-card-body']}>
                <form onSubmit={forgetSubmit}>
                    <Input type="number" placeholder={langChange.loginPhone} value={phone} event={phoneHandler} error={errorPhone} />
                    <div className={classes['forgotpass-submit']}>
                          <Button label={langChange.verify} type="submit" bgColor="#6b67eb" bgHover="#333258" disable={loading} loading={loading}/>
                          <div className={classes['forgot-text']}>
                            <Link to="/login">
                                <span>{langChange.alreadyAcc} {langChange.login}</span>
                            </Link>
                          </div>
                    </div>
                </form>
            </div>
      </div>
    </div>
  )
}

export default ForgotPassword
