import { configureStore } from '@reduxjs/toolkit'
import sidebarSlice from './slices/sidebarSlice'
import depositSlice from './slices/depositSlice'
import logoutSlice from './slices/logoutSlice'
import registerSlice from './slices/registerSlice'
import langSlice from './slices/langSlice'
import loginSlice from './slices/loginSlice'
import promotionSlice from './slices/promotionSlice'

export const store = configureStore({
  reducer: {
    sidebarControlSlice: sidebarSlice,
    depositStoreSlice: depositSlice,
    logoutStatusSlice: logoutSlice,
    registerSlice: registerSlice,
    lang: langSlice,
    login: loginSlice,
    promotionShow: promotionSlice,
  },
})