import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import classes from '../assets/styles/Verify.module.css';
import Button from '../utils/Button';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import logo from '../assets/logo/logo.png'
import { postMethod } from '../services';
import { REGISTER_API } from '../services/constant';
import SweetAlert from 'sweetalert2';
import config from '../config/config.json';
import { langChange } from '../lang';

const Verify = () => {
  const [loading,setLoading] = useState(false);
  const [error,setError] = useState("");
  const [otp, setOtp] = useState('');
  const {registerVal} = useSelector(state => state.registerSlice);
  const navigate = useNavigate();

  const { lang } = useSelector((state) => state.lang);
  useEffect(() => { }, [lang]);

  useEffect(()=>{
    const token = localStorage.getItem('r_c_auth')
    token && navigate('/')
  },[])

  useEffect(() => {
     if(!registerVal.name){
         navigate('/signup');
     };
  },[registerVal]);
  const submitHandler = async (e) => {
      e.preventDefault();
      setLoading(true);
      const data = {
         ...registerVal,
         otp,
         merchant_id: config.merchant_id,
         app_name_id: config.app_name_id
      }
      const res = await postMethod(REGISTER_API, data);
      if(res){
         setLoading(false);
         if(res === 'Failed to fetch'){
          SweetAlert.fire({
            width: '330px',
            icon: 'error',
            title: 'Connection!',
            text: langChange.please_check_your_internet_connection,
            confirmButtonText: 'Ok',
          });
        };
        if(res?.con){
          SweetAlert.fire({
            width: '330px',
            icon: 'success',
            title: 'Success!',
            text: langChange.registerSuccess,
            confirmButtonText: 'Go website',
          }).then(result => {
              if(result.isConfirmed){
                  localStorage.setItem('r_c_auth', JSON.stringify(res.data));
                  navigate('/');
              };
          });
        }else{
          if(res?.data?.error){
            setError(res?.data?.error);
          };
          if(res?.data?.name){
            setError(res?.data?.name[0]);
          };
          if(res?.data?.phone){
            setError(res?.data?.phone[0]);
          };
        };
      }
  };
  useEffect(()=>{setError("")},[otp])
  return (
    <div className={classes['container-main']}>
      <div className={classes['logo-position']}>
           <img src={logo} alt="logo" className={classes.logo} onClick={()=> navigate('/')}/>
      </div>
      <div className={classes['auth-card']}>
            <div className={classes['auth-card-header']}>
              {langChange.verify}
            </div>
            <div className={classes['auth-card-body']}>
                <form onSubmit={submitHandler}>
                    <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        inputType='number'
                        renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
                        renderInput={(props) => <input {...props} className={classes['opt-input']} />}
                    />
                    {
                      error &&
                      <span className={classes['error-message']}>{error}</span>
                    }
                    <div className={classes['verify-submit']}>
                        <Button label={langChange.register} type="submit" disable={otp.length === 6 ? false: true} bgColor="#5abc1c" bgHover="#344e22" loading={loading}/>
                    </div>
                </form>
            </div>
      </div>
    </div>
  )
}

export default Verify
