import React, { useEffect } from 'react';
import classes from '../assets/styles/Promotion.module.css'
import { langChange } from '../lang';
import { useDispatch, useSelector } from 'react-redux';
import { promotionShowHandler } from '../store/slices/promotionSlice';

export default function PromotionItem({ promotion, event, appliedPromotions, loading }) {
    const dispatch = useDispatch();
    const { promotionShow } = useSelector(state => state.promotionShow);
    const { lang } = useSelector((state) => state.lang);

    const isApplied = appliedPromotions.filter(promo => promo.promotion_name === promotion.en_title);

    useEffect(() => { }, [lang, promotionShow]);
    return (
        <>
            <div className={classes.promotion_item}>
                <div className={classes.promotion_period}>
                    <strong className={classes.promotion_period_item}>{langChange.from} : {promotion.from}</strong>
                    <strong className={classes.promotion_period_item}>{langChange.to} : {promotion.to}</strong>
                </div>
                <img style={{ width: '100%', background: '#000' }} src={promotion.photo} alt="Promotion Image" />
                <h2 className={classes.promotion_title}>{promotion.en_title}</h2>
                <div className={classes.promotion_action}>
                    <button className={classes.promotion_btn} style={{ background: isApplied.length > 0 && '#333258', border: isApplied.length > 0 && '1px solid #333258', cursor: isApplied.length > 0 && 'auto' }} onClick={event} disabled={loading === promotion.id || isApplied.length > 0}>
                        {loading === promotion.id ? (
                            <div className={classes.loading_container}>
                                {langChange.loading}
                                <div className={classes.dot_flashing}></div>
                            </div>
                        ) : (
                            <span>
                                {isApplied?.length > 0 ? `${langChange.applied}` : `${langChange.apply}`}
                            </span>
                        )}
                    </button>
                    <button className={classes.promotion_btn} onClick={() => dispatch(promotionShowHandler(promotion.id))}>{langChange.moreInfo}</button>
                </div>
            </div>
        </>
    )
}
